import { Carousel } from "react-responsive-carousel";
import { Box, IconButton, Button, Typography, useMediaQuery } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { car, instaphoto, tiktok } from '../../assets';
import React from 'react';


// imports all images from assets folder
const importAll = (r) =>
  r.keys().reduce((acc, item) => {
    acc[item.replace("./", "")] = r(item);
    return acc;
  }, {});

export const heroTextureImports = importAll(
  require.context('../../assets/herophotos', false, /\.(png|jpe?g|svg|)$/)
);

const MainCarousel = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  return (
    <div id="top">
      <Box
        display='flex'
        style={{ height: '100vh' }}
        position='fixed'
        justifyContent='center'
        marginX='0' // Center horizontally
        width={{ sm: '100vw' }} // Take up full screen width on small screens
      >
        <Box
          width={{ sm: '100%', md: '60%', lg: '45%' }}
          justifyContent='center'
          display='flex'
          marginX='20px'
        >
          <Box
            display='flex'
            top='50%'
            width={{ sm: '100%', md: '600px' }}
            height='100%'
            alignItems='center'
            justifyContent='center'
            zindex='5'
          >
            <Box
              display='flex'
              flexDirection='column'
              columnGap='20px'
              padding='10px'
              margin={{ sm: 'auto' }}
              justifyContent='center'
              alignItems='center'
            >
              {/* <Typography
                variant="h1"
                fontSize={['42px', '60px']}
              > */}
              <Typography
                variant="h1"
                fontSize={['42px', '60px']}
                align="center"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  marginLeft: '10px' // Space between links and text
                }}
              >

                Down to the Details
              </Typography>

              <Box
                marginTop="5px"
                marginBottom="10px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                maxWidth="90%"
                gap="20px"
              >
                {/* Social Links */}
                <Box
                  display="flex"
                  alignItems="center"
                  gap="20px"
                >
                  <a
                    href="https://www.instagram.com/down2the_detail/?igshid=YmMyMTA2M2Y%3D"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={instaphoto}
                      alt="Instagram"
                      width="30"
                      height="30"
                    />
                  </a>
                  <a
                    href="https://www.tiktok.com/@down2detail602?_t=ZT-8sKM2PkNRLa&_r=1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={tiktok}
                      alt="TikTok"
                      width="30"
                      height="30"
                    />
                  </a>
                </Box>

                {/* Centered Text */}
                <Typography variant="h8">
                  A clean vehicle, a clean image, and a clean lifestyle
                </Typography>
              </Box>

              <Box
                justifyContent='center'
                display='flex'
              >
                <a
                  href="https://calendly.com/down2the_detail/estimate?month=2023-07"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      borderRadius: '0',
                      width: { sm: '100%', md: '500px' },
                      height: '50px',
                      fontWeight: 'bolder',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 1, // Add space between text and icon
                    }}
                  >
                    <img
                      src={car} // Replace with your image path
                      alt="Appointment Icon"
                      style={{ width: '50px', height: '50px' }} // Adjust size as needed
                    />
                    SCHEDULE AN APPOINTMENT
                  </Button>
                </a>
              </Box>
            </Box>
          </Box>
        </Box>
        {isNonMobile && (
          <Box
            display='flex'
            zindex='2'
            width='60%'
            style={{ height: '100%', overflow: 'hidden' }}
            sx={{
              right: '0',
            }}
            marginRight='0'
          >
            <Carousel
              infiniteLoop={true}
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              autoPlay={true}
              interval={6000}
              renderArrowPrev={(onClickHandler, hasPrev, label) => (
                <IconButton
                  onClick={onClickHandler}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "0",
                    color: "white",
                    padding: "25px",
                    zIndex: "10",
                    opacity: 0, // Initially hide the arrow
                    transition: "opacity 0.3s", // Add a smooth transition
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.opacity = "1"; // Show the arrow on hover
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.opacity = "0"; // Hide the arrow when not hovered
                  }}
                >
                  <NavigateBeforeIcon sx={{ fontSize: 40 }} />
                </IconButton>
              )}
              renderArrowNext={(onClickHandler, hasNext, label) => (
                <IconButton
                  onClick={onClickHandler}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    right: "0",
                    color: "white",
                    padding: "25px",
                    zindex: "10",
                    opacity: 0, // Initially hide the arrow
                    transition: "opacity 0.3s", // Add a smooth transition
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.opacity = "1"; // Show the arrow on hover
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.opacity = "0"; // Hide the arrow when not hovered
                  }}
                >
                  <NavigateNextIcon sx={{ fontSize: 40 }} />
                </IconButton>
              )}
            >
              {Object.values(heroTextureImports).map((texture, index) => (
                <Box
                  key={`carousel-image-${index}`}
                  style={{
                    width: "100%",
                    height: "100vh", // Set a fixed height for the Box
                    overflow: "hidden", // Hide any overflow from the image
                  }}
                >
                  <img
                    src={texture}
                    alt={`carousel-${index}`}
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%", // Set the image height to 100% to fill the parent Box
                      objectFit: "cover",
                      backgroundAttachment: "fixed",
                    }}
                  />
                </Box>
              ))}
            </Carousel>
          </Box>
        )}
      </Box>
    </div>
  );
};


export default MainCarousel;
